<template>
	<v-container fluid>
		<v-row v-if="$authorized([`manager`,`marketing`])">
			<v-col
				v-for="(metric, index) in metrics"
				xs="12"
				sm="6"
				md="4"
				xl="4"
				:key="index"
			>
				<KeyMetrics
					:title="metric.title"
					:sub-title="metric.subTitle"
					:goal="metric.goal"
					:value="metric.value"
					:dateRangeName="metric.dateRangeName"
					:metricName="metric.name"
					@changed="getGoals"
					:forecast="metric.forecast"
					color="blue lighten-3"
				/>
			</v-col>
		</v-row>

		<v-row v-if="$authorized([`manager`,`marketing`])">
			<v-col
				v-for="(topList, index) in topLists"
				xs="12"
				sm="6"
				md="4"
				xl="4"
				:key="index"
			>
				<TopList
					:dimensions="topList.dimensions"
					:metrics="topList.metrics"
					:items="topList.items"
					:title="topList.title"
					:percentage="topList.percentage"
					:decimals="topList.decimals"
					:sub-title="topList.sub_title"
				/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import KeyMetrics from '@/components/material/KeyMetrics'
	import TopList from '@/components/material/TopList'

	export default {
		components: {
			KeyMetrics,
			TopList,
		},
		data () {
			return {
				metrics: [
					{
						name: `totalEngagement`,
						title: `North Star Metric (NSM)`,
						subTitle: `Agrees + Posts + Comments + Shares`,
						value: null,
						goal: null,
						forecast: null,
						dateRangeParams: ``,
						dateRangeName: `allHistory`,
						url: `/v2/statistics/total-engagement/`,
					},
					{
						name: `totalActiveUsers`,
						title: `Monthly Active Users (MAU)`,
						subTitle: `Last 31 days`,
						value: null,
						goal: null,
						forecast: null,
						dateRangeParams: `31d`,
						dateRangeName: `last31days`,
						url: `/v2/statistics/total-active-users/?metrics=users&e={"event_type":"_all"}&m=uniques&s=[{"prop":"platform","op":"is","values":["iOS","Android"]}]`,
					},
					{
						name: `totalMonthlySocialMediaReach`,
						title: `Monthly Social Media Reach`,
						subTitle: ``,
						value: null,
						goal: null,
						forecast: null,
						dateRangeParams: `31d`,
						dateRangeName: `last31days`,
						url: null,
					},
					{
						name: `totalAccounts`,
						title: `Members`,
						subTitle: `-`,
						value: null,
						goal: null,
						forecast: null,
						dateRangeParams: ``,
						dateRangeName: `allHistory`,
						url: `/v2/statistics/total-private-profiles/?include_newsletter_members=true`,
					},
					{
						name: `totalClimateDialogue`,
						title: `Partners`,
						subTitle: ``,
						value: null,
						goal: null,
						forecast: null,
						dateRangeParams: ``,
						dateRangeName: `allHistory`,
						url: `/statistics/total-climate-dialogue/`,
					},
					{
						name: `totalAnswers`,
						title: `Answered reviews`,
						subTitle: ``,
						value: null,
						goal: 0,
						forecast: null,
						dateRangeParams: ``,
						dateRangeName: `allHistory`,
						url: `/v2/statistics/total-posts/?answered=true`,
					},
				],
				topLists: [
					{
						title: `Top pages`,
						dimensions: `pagePath`,
						metrics: `sessions`,
						page_size: 10,
						timerange: ``,
						items: [],
					},
					{
						title: `Top Sources`,
						dimensions: `source`,
						metrics: `users`,
						page_size: 10,
						timerange: ``,
						items: [],
					},
					{
						title: `Top Countries`,
						dimensions: `country`,
						metrics: `users`,
						page_size: 10,
						timerange: ``,
						items: [],
						percentage: true,
						decimals: 1,
					},
				],
			}
		},
		methods: {
			refetch_all_data () {

				this.metrics.forEach(o => {
					if (!o.url) return

					this.$httpInt.get(o.url, {
						params: {
							forecast: o.forecast,
							timerange: o.dateRangeParams,
						}
					})
						.then(res => {
							if (o.name === `totalAccounts`) {
								const value = res.data.result.count
								this.$httpInt.get(`/v2/statistics/total-public-profiles/?forecast=true`)
									.then(res => o.value = res.data.result.count + value)
								this.$httpInt.get(`/v2/statistics/total-private-profiles/?forecast=true&timerange=7d`)
									.then(res => o.subTitle = `${res.data.result.count} new last 7 days`)
							} else {
								o.value = res.data.count ?? res.data.result.count
								o.forecast = res.data.forecast
							}
						})
				})

				this.topLists.forEach((o, i) => {
					this.$httpInt.get(`/v2/statistics/ga/?metrics=${o.metrics}&dimensions=${o.dimensions}&page_size=${o.page_size}&timerange=${o.timerange}&percentage=${o.percentage}`)
						.then(res => this.topLists[i].items = res.data.result)
				})

				this.getGoals()
			},
			getGoals() {
				this.$httpInt.get(`/statistics/goals`)
					.then(res => this.metrics.forEach(o => o.goal = res.data[o.name] && res.data[o.name][o.dateRangeName] || null))
			},
			reset() { Object.assign(this.$data, this.$options.data.apply(this)) },
		},
		mounted() {
			this.refetch_all_data()
		},
		name: `ViewDashboard`,
	}

</script>
