<template>
	<v-card class="pa-4 text-center">
		<v-container>
			<div class="text-h6">
				{{ title }}
			</div>
			<div class="text-body-1 grey--text text--darken-1">
				{{ subTitle }}
			</div>
			<v-simple-table dense>
				<template v-slot:default>
					<thead>
					<tr>
						<th class="text-left">
							{{ dimensions | capitalize }}
						</th>
						<th class="text-right">
							{{ metrics | capitalize }}
						</th>
					</tr>
					</thead>
					<tbody>
					<tr
						v-for="item in items"
						:key="item.key"
					>
						<td class="text-left">{{ item.key }}</td>
						<td class="text-right">{{ numberFormat(item.value) }}</td>
					</tr>
					</tbody>
				</template>
			</v-simple-table>
		</v-container>
	</v-card>
</template>

<script>

export default {
	methods: {
		numberFormat(number) {
			return number.toLocaleString(`sv-SE`,
				{
					maximumFractionDigits: this.decimals,
					minimumFractionDigits: this.decimals,
				}) + (this.percentage && `%` || ``)
		},
	},
	name: `MaterialTopList`,
	props: {
		metrics: {
			type: String,
			default: ``,
		},
		dimensions: {
			type: String,
			default: ``,
		},
		title: {
			type: String,
			default: ``,
		},
		subTitle: {
			type: String,
			default: ``,
		},
		items: {
		},
		percentage: {
			type: Boolean,
			default: false,
		},
		decimals: {
			type: Number,
			default: 0,
		},
	}
}
</script>